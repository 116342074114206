import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import {Container, Row, Col} from "reactstrap"
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioCard from "../components/portfolioCard"
import ProductCard from "../components/productCard"
import BlogCard from "../components/blogCard"

const MainIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`
  const posts = data.blog.edges
  const portfolioItems = data.portfolio.edges

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows:false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Home" />
        <p>No blog posts found. Add markdown posts to "content/blog" (or the directory you specified for the "gatsby-source-filesystem" plugin in gatsby-config.js).</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      
      <SEO title="Home" />

      <div className="hero">
        <Img alt="Screenshots of Em Em Digital websites and themes" fluid={data.headerImg.childImageSharp.fluid} />
        <div className="hero__text">
          <h1>Website Design &amp; Development</h1>
          <p>We specialize in content management system design and development.<br />Just looking for a beautiful theme to use? Yep, we have that, too.</p>
        </div>
      </div>

      <Container className="py-5">
        <h2 className="mb-4">Our Themes, Plugins and Layouts</h2>
        <Row className="products">
          <Col md="6">
            <ProductCard image={data.showoff1.childImageSharp.fluid} productTitle="em em travel" productType="Divi Layout" />
          </Col>
          <Col md="6">
            <ProductCard image={data.showoff2.childImageSharp.fluid} productTitle="em em café" productType="Wordpress Theme" />
          </Col>

        </Row>
      </Container>

      <section className="portfolioSection py-5 mb-5">
        <Container>
          
          <h2 className="mb-4">Our Portfolio</h2>

          <Slider {...settings}>

          {portfolioItems.map((portfolioItem) => {
                const post = portfolioItem.node
                return (
                  
                  <PortfolioCard post={post} />

                )
            })}  
          </Slider>
        
        </Container>
      </section>

      <Container>
        <h2 className="mb-4">Our Blog</h2>
        <Row>
          
            {posts.map((blogPost) => {
                const post = blogPost.node
                return (
                  
                  <Col key={`/blog${post.fields.slug}`} xs="6" lg="3">
                    <BlogCard post={post} orientation="portrait" />
                  </Col>  

                )
              
              

            })}         
          
        </Row>
      </Container>
 
    </Layout>
  )
}

export default MainIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "blog" } } }
      limit: 4
      ) {
      edges{
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            subtitle
            posttype
            thumbnail{
              childImageSharp{
                fluid(maxWidth:600, quality:80){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    portfolio: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "portfolio" } } }
      ) {
      edges{
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            subtitle
            posttype
            thumbnail{
              childImageSharp{
                fluid(maxWidth:600, quality:80){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    headerImg: file(relativePath: { eq: "header-v1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showoff1: file(relativePath: { eq: "showoff1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showoff2: file(relativePath: { eq: "showoff2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    portfolioItem1: file(relativePath: { eq: "thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`
