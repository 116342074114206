import React from 'react'
import Img from 'gatsby-image'

const ProductCard = ({ image, productTitle, productType }) => {

    const hide = false

    return(

        <div className="product-card">
            <div className="ribbon ribbon-top-right"><span>coming soon</span></div>
            <div className="product-card__figure">
            <Img alt="Product image" fluid={image} />
            </div>
            <div className="product-card__body">
            <div className="product-card__title-group">
                <h4 className="product-card__title">{productTitle}</h4>
                <h5 className="product-card__subtitle">{productType}</h5>
            </div>
            {hide &&
            <div className="product-card__price">
                <span>$5</span>
            </div>
            }
            {hide &&
            <div className="product-card__btns">
                <a href="https://google.com" className="btn">view live preview</a>
            </div>
            }
            </div>
        </div>

    )

}

export default ProductCard